<script>
import { mapActions } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import moment from 'moment'
import reportsMixin from '@/modules/Reports/reportsMixin'

export default {
  name: 'ReportSurveyAnswersList',
  components: {
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Datepicker: () => import('@/components/general/Datepicker'),
    FormSection: () => import('@/components/general/FormSection'),
    SelectField: () => import('@/components/general/SelectField'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm')
  },

  mixins: [ reportsMixin ],
  data () {
    return {
      formData: {
        dateInit: '',
        dateEnd: '',
        solutionType: null,
        solutions: [],
        learningPath: [],
        programs: [],
        survey: null,
        reportId: null
      },

      foundSurveys: null,
      toolTypes: [
        {
          text: 'Solução',
          value: 'solution'
        },
        {
          text: 'Programa',
          value: 'program'
        }
      ],

      toolTypeSelected: null,
      allProgramsList: [],
      filteredProgramsList: [],
      solutionTypeList: [],
      allSolutionsList: [],
      filteredSolutionsList: [],
      paths: [],
      modalWarning: false
    }
  },

  computed: {
    solutionsListPaginated () {
      return this.$store.getters.solutionsList
    },

    programsListPaginated () {
      return this.$store.getters.programsList
    },

    disabledSolution () {
      return !this.formData.solutionType || !this.formData.survey
    },

    disabledProgram () {
      return !this.formData.survey
    },

    getSolutionType () {
      return this.solutionTypeList.filter(solutionType => {
        return solutionType.value === this.formData.solutionType
      })[0]
    }
  },

  validations: {
    toolTypeSelected: {
      required
    },

    formData: {
      solutionType: {
        required: requiredIf(function () {
          return this.toolTypeSelected === 'solution'
        })
      },

      solutions: {
        required: requiredIf(function () {
          return this.toolTypeSelected === 'solution'
        })
      },

      programs: {
        required: requiredIf(function () {
          return this.toolTypeSelected === 'program'
        })
      },

      survey: {
        required
      },

      dateInit: {
        required
      },

      dateEnd: {
        required
      }
    }
  },

  watch: {
    'formData.survey' () {
      switch (this.toolTypeSelected) {
        case 'solution':
          if (!this.formData.solutionType) {
            return
          }

          this.getSolutionsByTypeAndSurvey()
          break
        case 'program':
          this.getProgramsBySurvey()
          break
      }
    },

    'formData.solutionType' () {
      if (!this.formData.survey) {
        return
      }

      this.getSolutionsByTypeAndSurvey()
    }
  },

  created () {
    this.setFetching(true)

    Promise.all([
      this.getSolutionsTypesList(),
      this.getLearningPathsList(),
      this.getSurveys()
    ]).finally(() => {
      this.setFetching(false)
    })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetSolutionsTypesList',
      'attemptGetSolutionsList',
      'attemptGetProgramsList',
      'attemptGetLearningPathsList',
      'attemptGetReportListSurveyAnswers',
      'attemptGetSurveyList',
      'attemptCheckReport',
      'attemptReportDownload'
    ]),

    getReportSurveyList () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      const initDate = moment(this.formData.dateInit)
      const finalDate = moment(this.formData.dateEnd)

      if (finalDate.diff(initDate, 'days') > 31) {
        this.modalWarning = true
      } else {
        this.generateReport()
      }
    },

    generateReport () {
      this.modalWarning = false
      const params = {
        createdDateInit: this.formData.dateInit,
        createdDateEnd: this.formData.dateEnd,
        surveyId: this.formData.survey,
        solutionIds: this.formData.solutions,
        programIds: this.formData.programs
      }

      this.setFetching(true)

      this.attemptGetReportListSurveyAnswers(params).then((response) => {
        this.reportId = response.data.id
        this.checkReport()
      }).catch(() => {
        this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
      })
    },

    getLearningPathsList () {
      return this.attemptGetLearningPathsList({ limit: 300 }).then(pagination => {
        if (pagination.data) {
          this.paths = pagination.data.map((element) => {
            return {
              text: element.name,
              value: element.id,
              solutions: element.learningPathSolutions
            }
          })
        }
      })
    },

    getSolutionsLearningPath (learningPath) {
      learningPath.solutions.map((solution) => {
        this.formData.solutions.push(solution.solution.id)

        return solution
      })
    },

    getSolutionsTypesList () {
      this.setFetching(true)

      this.attemptGetSolutionsTypesList().then(pagination => {
        if (pagination.data) {
          let solutionTypes = pagination.data

          solutionTypes = this.convertSolutionTypesGroups(solutionTypes)

          solutionTypes.forEach(element => {
            this.solutionTypeList.push({
              text: element.name,
              value: element.alias,
              id: element.id,
              group: element.group
            })
          })

          return true
        }
      }).finally(() => {
        this.setFetching(false)
      })
    },

    getSolutions (pagination) {
      return new Promise((resolve) => {
        this.attemptGetSolutionsList(pagination).then((paginationResponse) => {
          this.allSolutionsList.push(...this.solutionsListPaginated)

          if (pagination.page < paginationResponse.lastPage) {
            pagination.page += 1
            resolve(this.getSolutions(pagination))
          } else {
            resolve()
          }
        })
      })
    },

    getPrograms (pagination) {
      return new Promise((resolve) => {
        this.attemptGetProgramsList(pagination).then((paginationResponse) => {
          this.allProgramsList.push(...this.programsListPaginated)

          if (pagination.page < paginationResponse.lastPage) {
            pagination.page += 1
            resolve(this.getPrograms(pagination))
          } else {
            resolve()
          }
        })
      })
    },

    getSurveys () {
      this.setFetching(true)

      this.attemptGetSurveyList('satisfaction').then(({ data }) => {
        this.foundSurveys = data.data.map(survey => {
          return {
            text: survey.name,
            value: survey.id
          }
        }) || []
      }).finally(() => {
        this.setFetching(false)
      })
    },

    getSolutionsByTypeAndSurvey () {
      this.filteredSolutionsList = []
      this.allSolutionsList = []
      this.formData.solutions = []
      this.formData.learningPath = []
      this.formData.programs = []

      if (!this.formData.solutionType) {
        return
      }

      const solutionType = this.getSolutionType
      const solutionsfilter = solutionType.group !== null
        ? { solution_type_group: solutionType.group }
        : { solution_type: solutionType.id }

      solutionsfilter.survey_id = this.formData.survey

      this.setFetching(true)

      this.getSolutions({
        filter: solutionsfilter,
        order: { name: 'ASC' },
        limit: 9999,
        page: 1
      }).finally(() => {
        this.allSolutionsList.forEach((solution) => {
          this.filteredSolutionsList.push({
            text: solution.name,
            value: solution.id
          })
        })

        this.setFetching(false)
      })
    },

    getProgramsBySurvey () {
      this.allProgramsList = []
      this.formData.programs = []
      this.formData.solutions = []
      this.formData.learningPath = []
      this.filteredProgramsList = []

      const programsFilter = { survey_id: this.formData.survey }

      this.setFetching(true)

      this.getPrograms({
        filter: programsFilter,
        order: { name: 'ASC' },
        limit: 9999,
        page: 1
      }).finally(() => {
        this.allProgramsList.forEach((program) => {
          this.filteredProgramsList.push({
            text: program.name,
            value: program.id
          })
        })

        this.setFetching(false)
      })
    },

    closeModal () {
      this.modalWarning = false
    },

    leave () {
      this.$router.push({ name: 'reports.index' })
    },

    downloadReport () {
      this.attemptReportDownload(this.reportId).then((response) => {
        const url = window.URL.createObjectURL(new Blob([ response.data ]))
        const link = document.createElement('a')

        link.href = url

        link.setAttribute(
          'download',
          this.$t('reports.generate:report.survey.answers.list.file.name').toString()
        )

        document.body.appendChild(link)
        link.click()
      }).catch(() => {
        this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
      })
    },

    checkReport () {
      const interval = setInterval(() => {
        this.attemptCheckReport(this.reportId).then((response) => {
          if (response.data.status === 'generated') {
            this.setFetching(false)
            this.downloadReport()
            clearInterval(interval)
          } else if (response.data.status === 'failed') {
            this.setFetching(false)
            this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
            clearInterval(interval)
          }
        })
      }, 5000)
    }
  }
}
</script>

<template>
  <div class="main-content reports-create">
    <ContentHeader
      :title="$t('reports.generate:report.survey.answers.list.title')"
      light-theme
      fixed
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.reports')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave"
      />
      <ActionBar slot="actionbar" />
    </ContentHeader>
    <div class="main-content-inner">
      <div class="center">
        <div class="solutions-create-header">
          <h2
            class="solutions-create-title"
            v-html="$t('reports.generate:header.description')"
          />
        </div>
        <form class="form">
          <FormSection :title="$t('global:form.filters')">
            <SelectField
              v-model="toolTypeSelected"
              :label="$t('global:form.survey.tool.type')"
              :items="toolTypes"
              :validation="$v.toolTypeSelected"
            />
            <SelectField
              v-if="toolTypeSelected === 'solution'"
              v-model="formData.solutionType"
              :label="$t('global:form.solutions.type')"
              :items="solutionTypeList"
              :validation="$v.formData.solutionType"
            />
            <div v-if="toolTypeSelected === 'program' || formData.solutionType">
              <SelectField
                v-model="formData.survey"
                :label="$t('global:form.survey')"
                :items="foundSurveys"
                :validation="$v.formData.survey"
              />
              <SelectField
                v-if="toolTypeSelected === 'solution'"
                v-model="formData.learningPath"
                :label="$t('global:form.learning.path')"
                :items="paths"
                :disabled="disabledSolution"
                multiple
                :select-all-option="true"
                :searcheable="true"
                @change="getSolutionsLearningPath"
              />
              <SelectField
                v-if="toolTypeSelected === 'solution'"
                v-model="formData.solutions"
                :label="$t('global:form.solution')"
                :items="filteredSolutionsList"
                :disabled="disabledSolution"
                :validation="$v.formData.solutions"
                multiple
                :select-all-option="true"
                :searcheable="true"
              />
              <SelectField
                v-if="toolTypeSelected === 'program'"
                v-model="formData.programs"
                :label="$t('global:menu.programs')"
                :items="filteredProgramsList"
                :disabled="disabledProgram"
                :validation="$v.formData.programs"
                multiple
                :select-all-option="true"
                :searcheable="true"
              />
              <Datepicker
                v-model="formData.dateInit"
                :label="$t('global:form.filters.date.init')"
                :validation="$v.formData.dateInit"
              />
              <Datepicker
                v-model="formData.dateEnd"
                :label="$t('global:form.filters.date.end')"
                :validation="$v.formData.dateEnd"
              />
            </div>
          </FormSection>
          <Action
            slot="button"
            primary
            large
            fixed-width
            type="button"
            :text="$t('reports.generate:btn.generate')"
            @click="getReportSurveyList"
          />
        </form>
      </div>
    </div>
    <ModalConfirm
      :active="modalWarning"
      :title="$t('reports:report.survey.answers.list.title')"
      :description="$t('reports.generate:report.modal.description')"
      @cancelAction="closeModal"
      @confirmAction="generateReport"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/solutions.css"></style>
